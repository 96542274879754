<template>
  <div class="videoManage content">
    <div class="contentbox">
      <el-container>
        <el-scrollbar style="min-width: 300px !important; height: 100%">
          <el-aside style="margin-right: 0 !important">
            <div class="tree-container">
              <el-tree
                class="tree filter-tree"
                :data="dataRiver"
                :props="defaultProps"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                :default-expanded-keys="defaultExpaneded"
                :highlight-current="true"
                :row-key="id"
                :node-key="id"
                :current-node-key="currentNodekey"
                @node-click="handleNodeClick"
                ref="treeRiver"
              >
                <span slot-scope="{ node, data }">
                  <i v-if="data.children" class="el-icon-folder"></i>
                  <!-- <i v-else class="el-icon-collection-tag"></i> -->
                  <i v-else class="el-icon-tickets"></i>
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
              <div class="p-t-10"></div>
            </div>
          </el-aside>
        </el-scrollbar>
        <el-scrollbar class="wfull">
          <el-main>
            <div ref="rightDevW" class="rightBox">
              <TableQuery
                :formList="formList"
                :formInline="formInline"
                :customArrKey="customArrKey"
                :btnList="btnList"
                :property="queryProperty"
                @btnClickFun="btnClickFun"
                @moveQueryFun="queryBackFun"
                @formDataFun="formDataFun"
              >
              </TableQuery>
              <div class="p-t-10"></div>
              <div ref="tableBox">
                <SuperTable
                  ref="mytable"
                  :tableData="tableData"
                  :loading="tableLoading"
                  :property="tableProperty"
                  :pageData="pageData"
                  @handleCurrentChange="handleCurrentChange"
                  @handleSizeChange="handleSizeChange"
                  @operationClick="operationClick"
                  @cellClick="cellClick"
                >
                  <template v-slot:initVideo="slotProps">
                    <div>
                      <div v-if="slotProps.props.initialize == 1">
                        <i class="iconfont icon-video-right-1"></i>
                      </div>
                      <div v-if="slotProps.props.initialize != 1">
                        <el-button
                          v-if="!slotProps.props.showProgress"
                          type="primary"
                          size="mini"
                          @click="initializeSdlVideo(slotProps.props)"
                          >视频切片</el-button
                        >
                        <el-progress
                          v-if="slotProps.props.showProgress"
                          :percentage="slotProps.props.progress"
                          color="#72C22D"
                        ></el-progress>
                      </div>
                    </div>
                  </template>
                </SuperTable>
              </div>
            </div>
          </el-main>
        </el-scrollbar>
      </el-container>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
import SuperTable from "@/common/module/SuperTable";
import io from "socket.io-client";
export default {
  name: "videoManage",
  components: {
    TableQuery,
    SuperTable
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          name: "videoname",
          label: "视频名称",
          showhide: true,
          type: "text",
          clearable: true,
          placeholder: "请输入视频名称"
        }
      ],
      formInline: {
        videoname: ""
      },
      customArrKey: [],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "查询",
          type: "searchButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "cedian",
        moveLabelWidth: "120px"
      },
      /* 表格数据 */
      tableData: {
        listData: [],
        titleData: [],
        btnData: []
      },
      /* 表格加载 */
      tableLoading: false,
      /* 表格属性 */
      tableProperty: {
        typeName: "",
        emptyText: "",
        fixheight: false,
        isOrder: true, // 设置表格有序号
        // border: true, // 设置表格有边框
        operationWidth: "140px",
        hideOperation: true
      },
      /* 分页 */
      pageData: {
        currentPage: 1,
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      },
      loading: false,
      btnAuthority: false,
      websocket: {},
      videoId: [],
      dataRiver: [],
      defaultExpaneded: [], //默认展开的节点
      defaultProps: {
        children: "nodes",
        label: "text"
      },
      id: "typeCode",
      typecode: "",
      currentNodekey: "", //默认选中的节点树
      filePath: {}
    };
  },
  async created() {
    this.$api("videoManage.getVideoTypeTree")
      .invoke()
      .then(({ data: { data } }) => {
        this.dataRiver = [{ typeCode: "", text: "全部", nodes: data.data }];
        this.defaultExpaneded = [this.dataRiver[0].nodes[0].typeCode];
        this.defaultExpaneded = [""];
        this.currentNodekey = "";
        this.$nextTick(() => {
          this.$refs.treeRiver.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
        });
      });
    this.resetArr();
    this.getTableList(true); // 表格列表数据
  },
  mounted() {
    this.cssFun();
    let that = this;
    // eslint-disable-next-line prettier/prettier
    window.onresize = function () {
      that.cssFun();
    };
  },
  methods: {
    /*样式*/
    cssFun() {
      let that = this;
      // eslint-disable-next-line prettier/prettier
      that.$nextTick(function () {
        // eslint-disable-next-line prettier/prettier
        setTimeout(function () {
          that.$refs.tableBox.style.height =
            window.innerHeight -
            that.$refs.tableBox.offsetTop -
            120 -
            48 +
            "px";
          if (that.$refs.mytable) {
            that.$refs.mytable.tableCssFun();
            that.$refs.mytable.scrollTableCssFun();
          }
        }, 200);
      });
    },
    resetArr() {
      this.tableData.titleData = [
        {
          headeralign: "center",
          prop: "typename",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频类型",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "videoname",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频名称",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "memo",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频简介",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "zruser",
          showoverflowtooltip: true,
          showhide: true,
          label: "责任人",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "filesize",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频大小",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "suffix",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频格式",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "createtime",
          showoverflowtooltip: true,
          showhide: true,
          label: "添加时间",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "statusName",
          showoverflowtooltip: true,
          showhide: true,
          label: "状态",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "a",
          showoverflowtooltip: true,
          showhide: true,
          label: "操作",
          type: "icon_click",
          align: "center",
          width: 150
        },
        {
          headeralign: "center",
          prop: "b",
          name: "initVideo",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频切片",
          type: "slot",
          align: "center",
          width: 120
        }
      ];
      this.btnAuthority =
        this.$store.state.operationPower.edit.SHJ_ControllerUnit;
      if (this.btnAuthority) {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "iconxiangqing1",
            label: "详情",
            type: "detailsButton"
          }
        ];
      } else {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          },
          {
            name: "addButton",
            icon: "icon-xinzeng",
            label: "新增",
            type: "addButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "iconxiangqing1",
            label: "详情",
            type: "detailsButton"
          },
          {
            name: "editButton",
            icon: "iconbianji-copy",
            label: "编辑",
            type: "editButton"
          },
          {
            name: "deleteButton",
            icon: "iconshanchu",
            label: "删除",
            type: "deleteButton"
          },
          {
            name: "initVideoButton",
            icon: "iconshanchu",
            label: "视频切片",
            type: "initVideoButton"
          }
        ];
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      // console.log(data);
      this.currentNodekey = data.typeCode;
      this.typecode = data.typeCode;
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    getTableList(val) {
      let obj = {
        videoname: this.formInline.videoname,
        typecode: this.typecode,
        page: this.pageData.currentPage,
        rows: this.pageData.pageSize
      };
      this.$api("videoManage.getSdlVideoByList")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data) {
              data.data.map(item => {
                item.a = ["detailsButton", "editButton", "deleteButton"];
                item.b = ["initVideoButton"];
                item.statusName = item.status == "0" ? "正常" : "废弃";
                item.showProgress = false;
                item.progress = 0;
              });
              this.tableData.listData = data.data;
              console.log("this.tableData.listData2", this.tableData.listData);
              this.pageData.total = data.total;
              if (val) {
                const videoIds = JSON.parse(localStorage.getItem("videoId"));
                const filePath = JSON.parse(localStorage.getItem("filePath"));
                if (videoIds && videoIds.length) {
                  videoIds.map(videoId => {
                    this.tableData.listData.map(item => {
                      const path =
                        this.filePath[item.pkId] || filePath[item.pkId];
                      console.log("path", path);
                      if (item.pkId === videoId) {
                        this.splitVideo(item, path, true);
                      }
                    });
                  });
                }
              }
            }
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    /* 当前页-改变事件 */
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.getTableList();
    },
    /* 每页多少条-改变事件 */
    handleSizeChange(val) {
      this.pageData.currentPage = 1;
      this.pageData.pageSize = val;
      this.getTableList();
    },
    // 表格列表上的点击事件
    operationClick(item, scope) {
      let that = this;
      if (item.name === "detailsButton") {
        this.$router.push({
          path: "/index/leftMenuIndex/videoManageDetail",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName,
            id: scope.row.pkId
          }
        });
      } else if (item.name === "editButton") {
        this.$router.push({
          path: "/index/leftMenuIndex/videoManageEdit",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName,
            id: scope.row.pkId,
            isEdit: 1
          }
        });
      } else if (item.name === "deleteButton") {
        this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            that.deleteFun(scope.row);
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "已取消删除",
              duration: 1000
            });
          });
      }
    },
    // 删除
    deleteFun(val) {
      let obj = {
        pkId: val.pkId
      };
      this.$api("videoManage.deleteSdlVideo")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            this.$message({
              showClose: true,
              message: "删除成功！",
              type: "success",
              duration: 1000
            });
            this.getTableList();
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.pageData.currentPage = 1;
        this.getTableList();
      } else if (item.name === "addButton") {
        this.$router.push({
          path: "/index/leftMenuIndex/videoManageEdit",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName
          }
        });
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
    },
    cellClick(row, column) {
      if (row.fileurl) {
        console.log("播放视频");
      }
    },
    splitVideo(video, filePath, bool) {
      // 创建socket.io实例，连接到node端，使用视频id作为命名空间
      const websocket = io(`http://172.16.12.53:8353/${video.pkId}`);
      if (!bool) {
        // 发送切片请求
        websocket.emit("split", {
          file: filePath,
          duration: 3,
          id: video.pkId
        });
      } else {
        websocket.emit("resume", video.pkId);
      }
      video.showProgress = true;
      // 显示进度条
      video.progress = 0;
      // 监听切片进度事件
      websocket.on("progress", data => {
        const currenData = this.tableData.listData.filter(
          item => item.pkId === video.pkId
        );
        console.log("currenData", currenData);
        console.log(`视频${video.videoname}切片进度${data.percent}`);
        currenData[0].showProgress = true;
        // 更新进度百分比
        currenData[0].progress =
          data.percent === undefined ? 0 : parseInt(data.percent);
      });
      // 监听切片结束事件
      websocket.on("end", () => {
        // 隐藏进度条
        video.showProgress = false;
        video.initialize = "1";
        // 显示切片完成的提示
        this.$message({
          showClose: true,
          message: `视频${video.videoname}切片完成`,
          type: "success",
          duration: 1000
        });
        let videoIds = JSON.parse(localStorage.getItem("videoId"));
        this.videoId = videoIds.filter(videoId => videoId != video.pkId);
        if (this.videoId.length) {
          localStorage.setItem("videoId", JSON.stringify(this.videoId));
        } else {
          localStorage.removeItem("videoId");
        }
        // 断开连接
        websocket.disconnect();
      });
      websocket.on("error", err => {
        this.$message({
          showClose: true,
          message: `视频${video.videoname}切片错误：${err}`,
          type: "error",
          duration: 1000
        });
      });
      websocket.on("disconnect", () => {
        // 显示断开连接的提示
        console.log(`视频${video.videoname}已断开连接`);
      });
      websocket.on("id", id => {
        if (!this.videoId.includes(id)) {
          this.videoId.push(id);
        }
        localStorage.setItem("videoId", JSON.stringify(this.videoId));
      });
      this.websocket[video.pkId] = websocket;
    },
    initializeSdlVideo(row) {
      console.log("initializeSdlVideo");
      const params = {
        pkId: row.pkId,
        initialize: row.initialize
      };
      this.$api("videoManage.initializeSdlVideo")
        .invoke(params)
        .then(({ data: { code, data } }) => {
          this.filePath[row.pkId] = data;
          localStorage.setItem("filePath", JSON.stringify(this.filePath));
          this.splitVideo(row, data);
          // if (code === 1) {
          //   this.$message({
          //     showClose: true,
          //     message: "删除成功！",
          //     type: "success",
          //     duration: 1000
          //   });
          //   this.getTableList();
          // } else {
          //   this.$message({
          //     showClose: true,
          //     message: data,
          //     type: "error",
          //     duration: 1000
          //   });
          // }
        });
    }
  },
  beforeDestroy() {
    if (Object.keys(this.websocket).length) {
      for (let key in this.websocket) {
        this.websocket[key].emit("pause", key);
        // this.websocket[key].emit("stop");
        this.websocket[key].disconnect();
      }
    }
  }
};
</script>
<style scoped lang="less">
.videoManage {
  /deep/.el-table__cell:nth-child(3) {
    cursor: pointer;
  }
  .icon-video-right-1 {
    font-size: 20px;
    color: #41bf3a;
  }
  /deep/.el-table__row {
    .el-table__cell:nth-last-child(1) {
      padding: 0;
    }
  }
  /deep/.el-container {
    .wfull {
      width: 100%;
    }
  }
}
</style>
